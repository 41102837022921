@import 'variables';

.blog-content {
  margin-top: 150px;
  a {
    font-weight: 600!important;
    color: #aa1a18!important;
    text-decoration: underline!important;
    font-size: 18px;
  }
  .header {
    height: 125px;
    background-color: $color-white;
    position: relative;
    padding: 0px;
    border: none;
    z-index: 0;
    .title {
      p {
        color: $color-dark;
        font-family: 'Roboto';
        font-size: 36px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 42px;
        padding: 20px 0px 27px 180px;
        display: flex;
        align-items: center;
      }
      .blog-img-content {
        position: absolute;
        top: -90px;
        left: 100px;
        width: 564px;
        height: 386px;
        .blog-img {
          height: 100%;
          position: relative;
        }
      }
    }
    .search-input-content {
      align-items: center;
      .search-input-group {
        position: relative;
        width: 561px;
        height: 48px;
        input {
          width: 100%;
          padding: 5px 15px 5px 50px;
          border-radius: 4px;
          background-color: #f5f6f9;
          font-family: 'Roboto';
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #999898;
          height: 100%;
          &:focus {
            outline: none;
          }
        }
        .search-img-content {
          position: absolute;
          left: 18px;
          top: 15px;
          width: 19px;
          height: 19px;
          .search-img {
            position: relative;
            height: 100%;
          }
        }
      }
    }
  }

  .main-body {
    padding: 54px 167px 150px 169px;
    .first-section {
      position: relative;
      padding-right: 100px;
      .description-title {
        color: #000000;
        font-family: 'Roboto';
        font-size: 21px;
        font-style: italic;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
        text-align: justify;
      }
      .description-body {
        margin-top: 40px;
        color: #787676;
        font-family: 'Roboto';
        font-size: 16px;
        font-style: italic;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 24px;
        text-align: justify;
        padding-right: 30px;
      }
    }

    .second-section {
      padding: 118px 0px 0px 84px;
      position: relative;
      .custom-dropdown {
        right: 0px;
        top: 50px;
        @media screen and (max-width: 1440px) {
          right: -50px;
        }
      }
      .top-post {
        color: #404040;
        font-family: 'Roboto';
        font-size: 18px;
        letter-spacing: 0;
        line-height: 21px;
        position: relative;
        &::after {
          position: absolute;
          content: ' ';
          top: 35px;
          left: 0px;
          background: $color-red;
          height: 2px;
          width: 80px;
          display: block;
        }
      }
      .intro-content {
        margin-top: 35px;
        .intro-img-content {
          height: 260px;
          width: 367px;
          .intro-img {
            position: relative;
            height: 100%;
          }
        }
        .intro-title {
          margin-top: 16px;
          color: #4a4a4a;
          font-family: 'Roboto';
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 16px;
        }
      }
      .divider {
        margin-top: 20px;
        height: 1px;
        background-color: #ececec;
      }
    }
  }
}

.blog-item-content {
  .blog-item {
    margin-top: 60px;
    cursor: pointer;
    .image-section {
      .article-img-content {
        width: 300px;
        height: 180px;
        .article-img {
          position: relative;
          height: 90%;
        }
      }
    }
    .description-section {
      padding: 0px 20px 0px 40px;
      .article-title {
        color: $color-dark;
        font-family: 'Roboto';
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 19px;
      }
      .article-description {
        margin-top: 0px;
        padding-top:0px ;
        color: #787676;
        font-family: 'Roboto';
        font-size: 14px;
        font-style: italic;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 19px;
        text-align: justify;
        height: 99px;
        overflow: hidden;
      }
    }
  }
}

.article-date {
  span {
    color: #404040;
  }
  margin-top: 30px;
  color: #9a9a9a;
  font-family: 'Roboto';
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
}

.blog-list-content {
  .blog-list-item {
    padding-top: 21px;
    padding-bottom: 21px;
    border-bottom: 1px solid #ececec;
    cursor: pointer;
    .blog-item-img-content {
      .blog-item-img {
        position: relative;
        height: 100px;
        width: 104px;
      }
    }
    .blog-item-content {
      padding-left: 21px;
      .blog-item-title {
        color: #4a4a4a;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 16px;
      }
      .article-date {
        margin-top: 10px;
      }
    }
  }
  .load-more-content {
    margin-top: 126px;
    .arrow-img-content {
      width: 19px;
      height: 20px;
      .arrow-img {
        position: relative;
        height: 100%;
      }
    }
    .load-more-btn {
      align-items: center;
      margin-left: 15px;
      color: #9a9a9a;
      font-family: 'Roboto';
      font-size: 14px;
      letter-spacing: 0;
      line-height: 16px;
      &:focus {
        outline: none;
      }
    }
  }
}

.back-btn-content {
  .back-btn-img-content {
    width: 19px;
    height: 20px;
    .back-btn-img {
      position: relative;
      height: 100%;
    }
  }
  .back-btn {
    align-items: center;
    margin-left: 15px;
    letter-spacing: 0;
    z-index: 1;
    color: #222222;
    font-family: 'Roboto';
    font-size: 15px;
    letter-spacing: 0;
    line-height: 18px;
    &:focus {
      outline: none;
    }
  }
}

.blog-beta-header {
  margin-top: 74px;
  height: 87px;
  background-color: $color-red;
  position: relative;
  align-items: center;
  padding-top: 15px;
  cursor: pointer;
  .img-content {
    position: absolute;
    left: 40px;
    top: -30px;
    height: 164px;
    width: 157px;
    .beta-img {
      position: relative;
      height: 100%;
    }
  }
  .title {
    color: #080808;
    font-family: 'Roboto';
    font-size: 36px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 42px;
    text-align: left;
    padding-right: 0px;
    width: 30%;
  }
  .sub-title {
    margin-top: 8px;
    color: #fffdfd;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 42px;
    text-align: right;
    width: auto;
  }
}
.img-blog-header{
  float: left;
}
.img-blog-text{
  float: right;
}
.blog-detail-content {
  margin-top: 40px;

  .blog-detail-title {
    margin-top: 45px;
    color: #222222;
    font-family: Montserrat;
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
  }
  .blog-detail-description {
    margin-top: 15px;
    color: #222222;
    font-family: 'Roboto';
    font-size: 18px;
    letter-spacing: 0;
    line-height: 31px;
    text-align: justify;
    p{
      font-weight: 200!important;
    }
  }
  P span{
    font-weight: 200!important;
  }
}


.left-btn-img-content {
  height: 16px;
  width: 26px;
  .left-btn-img {
    position: relative;
    height: 100%;
  }
}
.right-btn-img-content {
  margin-left: 10px;
  margin-top: 23px;
  margin-right: 20px;
  height: 11px;
  width: 19px;
  .right-btn-img {
    position: relative;
    height: 100%;
  }
}

.login-modal-content {
  .description-title {
    color: #000000;
    font-family: 'Roboto';
    font-size: 21px;
    font-style: italic;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    text-align: justify;
  }
  .description-content {
    height: 70px;
    overflow: hidden;
    margin-top: 30px;
    color: #787676;
    font-family: 'Roboto';
    font-size: 16px;
    font-style: italic;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
    text-align: justify;
  }
  .contact-form-content .btn-contact {
    margin-top: 55px;
  }
}

@media screen and (max-width: 1024px) {
  .blog-content .header .title .blog-img-content {
    left: 34px;
    width: 414px;
    height: 283px;
    top: -45px;
  }

  .blog-content .header .title p {
    padding-left: 34px;
  }

  .blog-content {
    margin-top: 140px;
  }

  .blog-content .main-body {
    padding-left: 34px;
    padding-right: 34px;
  }

  .blog-content .main-body .second-section {
    padding-left: 0px;
    padding-top: 10px;
  }

  .blog-content .main-body .second-section .custom-dropdown {
    right: 25px;
    top: 0px;
  }

  .blog-content .header .search-input-content .search-input-group {
    width: 463px;
  }
}
.blog-content {
  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    width: 100%;
    padding: 0px 0px 10px 0px;
    margin: 4px 0px;
    strong span{
      font-weight: 400!important;
    }
  }
  h1{
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    width: 100%;
    padding: 0px 0px 10px 0px;
    margin: 4px 0px;
  }
  ol {  
    list-style: decimal;
    width: 100%;
    padding: 10px 0px 10px 50px;
    li{
      font-weight: 200!important;
      color: #222;
      font-family: 'Roboto';
      font-size: 18px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 21px;
      text-align: justify;
      font-style: normal;
      span{
        font-weight: 200!important;
        font-size: 18px;
        line-height: 31px;
      }
      strong{
        color: #222222;
        font-family: Roboto!important;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 31px;
        font-weight: 400!important;
        a{
          font-weight: 600!important;
          color: #aa1a18!important;
          text-decoration: underline!important;
          font-size: 18px;
        }
        span{
          color: #222222;
        font-family: Roboto!important;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 31px;
        font-weight: 400!important;
        }
      }
    }
  }
  h2 {
    font-size: 26px;
    line-height: 62px;
    height: 108px;
    padding-top: 20px;
    img {
      list-style-type: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      float: left;
    }
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 0em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    li {
      display: list-item;
      text-align: -webkit-match-parent;
      font-weight: 200;
      span{
        font-weight: 200!important;
      }
      strong{
        font-weight: 400!important;
        font-family: 'Roboto'!important;
      }
    }

    li::before {
      content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: #aa1a18; /* Change the color */
      font-weight: bold; /* If you want it to be bold */
      display: inline-block; /* Needed to add space between the bullet and the text */
      width: 1em; /* Also needed for space (tweak if needed) */
      margin-left: -1em; /* Also needed for space (tweak if needed) */
    }

  }
  img {
    padding: 0 50px 0 0
  }
  h3 {
    padding-top: 10px;
  }
}

.blog-img-left {
  float:left;
  width:400px;
  height:300px;
  position: relative;
  margin-right: 50px;
  img{
    visibility: inherit;
    position: relative!important;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px!important;
    border: none;
    margin: 0px!important;
    display: block;
    width: 400px!important;
    height: 300px!important;
    min-width: 400px!important;
    min-height: 300px!important;
    object-fit: contain;
  }
}

.content {
  float:right;
}