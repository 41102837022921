$primary-color: #7c9ac0; // blue=grey
$secondary-color: #222222;
$color-default-background: #f8f9fc;
$color-header-background: #fffbfb;
$color-header-border: #979797;
$color-white: #ffffff;
$color-dark: #222222;
$color-red: #aa1917;
$color-red1: #BB0002;
$color-red2: #b12e2d;

$fontSize-mobile-header: 44px;
$fontSize-mobile-header1: 16px;
$fontSize-mobile-header2: 14px;
$fontSize-mobile-header3: 10px;
$fontSize-mobile-description1: 9px;
$fontSize-mobile-description2: 8px;


