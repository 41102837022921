@import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";
@import "carousel.scss";
@import "dialog.scss";

@import "home.scss";
@import "blog.scss";
@import "legal.scss";

@import "mobile/home.scss";
@import "mobile/blog.scss";

html, body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  background: $color-default-background
}

.custom-dropdown{
  border-radius: 4px;
  background-color: #FFFFFF;
  position: absolute;
  right: -2px;
  box-shadow: none;
  border: none;
  z-index: 91;
  top: -8px;
  .dropdown{
    background: #ff000000;
  }
  ul{
    width: 189.8px;
    border: 0.8px solid #E1E1E1;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 5px 0 rgba(227,227,227,0.5);
    right: 0;
    margin-top: 5px;
    position: absolute;
  }
  button, ul li > a{
    color: rgba(0,0,0,0.87);
    font-family: 'Roboto';
    font-weight: normal;
    background-color: #ffffff;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
  }
  ul li > a {
    padding: 10px 0px 10px 24px;
    display: block;
    &:hover{
      background-color: #EEEEEE;
    }
  }
  ul li:first-child > a {
    border-radius: 4px 4px 0px 0px;
  }
  ul li:last-child > a {
    border-radius: 0px 0px 4px 4px;
  }

  button{
    color: #222222;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    width: 118px;
    padding: 10px 0px 10px 11px;
    background-color: #ffffff;
    box-shadow: none;
    border: 0.8px solid #E1E1E1;
    border-radius: 4px;
    &:focus {
      outline: none;
    }
    svg{
      width: 24px;
      height: 24px;
      margin-left: 20px;
      margin-top: -1px;
    }
    &.active svg{
      transform: rotate(180deg);
    }
  }
}

.red {
  color: $color-red;
}

.gmnoprint{
  display: none;
}

.gmnoprint.gm-style-cc{
  display: none;
}

.mobile .custom-dropdown button{
  padding-left: 10px;
}

.mobile {
  .modal
  {
    right: 0px;
  }

  .modal-content{
    padding: 56px 25px 0px 25px;
    height: 450px !important;
    width: 100% !important;
  }
}