@import "../variables.scss";

.mobile{
  //Header Section
  .header {
    height: 56px;
    padding: 20px 0px 20px 19px;
    border: solid 1px $color-header-border;
    background-color: $color-header-background;
    position: fixed;
    top: 0px;
    z-index: 100;

    .logo {
      margin-left: 46px;
      object-fit: contain;
    }


    .btn-bag{
      position: relative;
      .badge-cnt{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -5px;
        right: -5px;
        color: $color-white;
        border-radius: 10px;
        width: 11px;
        height: 11px;
        background: $color-red1;
        font-size: 6px;
      }
    }

    .btn-usericon{
      margin-left: 20px;
    }
  }

  .video_list_image {
    margin: 25px 0 0 0px;
    object-fit: contain;
    position: relative;
    height: 408px;

    @media screen and (min-width: 500px) {
      height: 500px;
    }

    @media screen and (min-width: 650px) {
      height: 540px;
    }

    .video_list_image_content{
      object-fit: contain;
      position: relative;
      align-items: center;
      overflow: hidden;
      height: 408px;
      @media screen and (min-width: 500px) {
        height: 500px;
      }
      @media screen and (min-width: 650px) {
        height: 540px;
      }
      .hero-title{
        color: #FFFFFF;
        font-family: 'Roboto';
        font-size: 18px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 19px;
        text-align: center;
        z-index:1;
      }
      .hero-description{
        margin-top: 16px;
        color: #F5DA68;
        font-family: 'Roboto';
        font-size: $fontSize-mobile-header1;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 19px;
        text-align: center;
        z-index:1;
      }
    }

    .web-instruction {
      position: absolute;
      width: 80%;
      height: 190px;
      padding: 24px 0px 14px 0px;
      border-radius: 4px;
      box-shadow: 0 10px 34px 0 rgba(210, 210, 210, 0.5);
      background-color: $color-white;
      bottom: -135px;
      .slick-arrow{
        display: block !important;
      }
      .slick-next {
        right: 10px;
      }

      .slick-prev {
        left: 10px;
        z-index: 1;
      }

      .slick-prev:before{
        content: '';
        background-image: url(/images/arrow-left-black.png);
        background-size: 15px 15px;
        width: 15px;
        height: 15px;
        display: block;
      }

      .slick-prev:before, .slick-next:before {
        font-family: 'Roboto';
        font-size: 20px;
        line-height: 1;
        opacity: 1;
        color: #000;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      .slick-next:before, [dir=rtl] .slick-prev:before {
        content: '';
        background-image: url(/images/arrow-right-black.png);
        background-size: 15px 15px;
        width: 15px;
        height: 15px;
        display: block;
      }
      .item{
        &:last-child .description{
          text-align: center;
          width: 100%;
          padding: 0px 39px;
        }
      }

      .logo {
        width: 40px;
        height: 40px;
        padding: 12px 10px;
        object-fit: contain;
        border-radius: 6px;
        border: solid 2px $color-red2;
        img {
          object-fit: contain;
        }
      }
      .title{
        color: $color-dark;
        font-family: 'Roboto';
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 11px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .underline-shape{
        span{
          display: block;
          width: 45px;
          height: 2px;
          margin-bottom: 18px;
          background-color: $color-red2;
        }
      }
      .description{
        height: 61px;
        width: 172px;
        color: #787676;
        font-family: 'Roboto';
        font-size: 15px;
        font-style: italic;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 21px;
        text-align: center;
      }
    }
  }

  .margin-auto {
    margin: auto;
  }

  .passion-title-content{
    margin-top: 200px;
    h2{
      color: #222222;
      font-family: 'Roboto';
      font-size: 20px;
      font-weight: 900;
      letter-spacing: 0;
      line-height: 19px;
      text-align: center;
    }
    p{
      color: #222222;
      font-family: 'Roboto';
      font-size: 20px;
      font-weight: 900;
      letter-spacing: 0;
      line-height: 19px;
      text-align: center;
    }

    .steps-slider-content{
      position: relative;
      .steps-slider-item{
        width: 170px;
        height: 250px;
        .steps-img{
          width: 166px;
          height: 139px;
          position: relative;
          margin-top: 20px;
        }
        .step-title{
          font-stretch: normal;
          font-style: normal;
          text-align: center;
          color: #222222;
          font-family: 'Roboto';
          font-size: 14px;
          font-weight: 900;
          letter-spacing: 1.03px;
          line-height: 23px;
          margin-top: 10px;
        }
      }
      .slick-dots{
        display: none !important;
      }
      .slick-arrow.slick-next{
        display: block !important;
        right: -30px;
        top: 92px;
        width: 60px;
        height: 60px;
      }

      .slick-arrow.slick-prev{
        display: block !important;
        left: -30px;
        top: 92px;
        width: 60px;
        height: 60px;
        z-index: 1;
      }

      .slick-prev:before, .slick-next:before{
        background: #000000;
        opacity: 0.4;
        width: 60px;
        height: 60px;
        font-size: 60px;
        border-radius: 30px;
        color: black;
      }

      .slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before{
        opacity: 0.5;
      }

      .slick-slider{
        overflow: hidden;
      }



      .icon-next-slider-arrow{
        position: absolute;
        width: 20px;
        height: 20px;
        font-size: 20px;
        color: white;
        right: 0px;
        top: 80px;
        z-index: 1;
        font-weight: bold;
        font-family: 'Roboto';
      }

      .icon-previous-slider-arrow{
        transform: rotate(180deg);
        position: absolute;
        width: 20px;
        height: 20px;
        font-size: 20px;
        color: white;
        left: 0px;
        top: 80px;
        z-index: 2;
        font-weight: bold;
        font-family: 'Roboto';
      }
    }
  }

  .beta-contact-mobile-content{
    margin-top: 30px;
    .title{
      font-family: 'Roboto';
      font-size: 23px;
      font-weight: 900;
      color: #080808;
      text-align: center;
      line-height: 19px;
    }
  }

  .beta-contact-content {
    background: $color-red;
    height: 134px;
    margin-top: 60px;
    padding: 8px 13px 9px 18px;
    position: relative;
    p.title {
      font-family: 'Roboto';
      font-size: 19px;
      font-weight: 900;
      color: #080808;
      text-align: center;
      height: 10px;
      line-height: 20px;
      height: 41px;
    }
    p.sub-title{
      margin-top: 0px;
      font-family: 'Roboto';
      font-size: 15px;
      font-weight: 700!important;
      color: #fffdfd;
      text-align: center;
      line-height: 10px;
    }
    .contact-form {
      z-index: 10;
      height: 30px;
      width: 260px;
      margin-top: 5px;
      background-color: transparent;
      border-radius: 4px;
      position: relative;
      .form-content{
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 90;
      }
      input{
        font-family: 'Roboto';
        width: 100%;
        height: 30px;
        font-style: italic;
        color: #787676;
        padding: 5px 60px 5px 20px;
        border-radius: 4px;
        font-size: $fontSize-mobile-header3;
        &:focus {
          outline: none;
        }
      }
      .btn-send-content{
        width: 51px;
        height: 30px;
        opacity: 0.06;
        background-color: #000000;
        position: absolute;
        right: 0px;
        top: 0px;
        border-radius: 0px 4px 4px 0px;
      }
      .btn-send{
        width: 51px;
        background: transparent;
        font-weight: 500;
        color: $color-red;
        position: absolute;
        right: 0px;
        top: 0px;
        height: 30px;
        background: transparent;
        padding: 8px 12.5px 8.65px 10.7px;
        font-size: 10px;
        &:focus{
          outline: none;
        }
      }
    }
    .beta-diagram-img-content{
      position: absolute;
      top: -97px;
      width: 85px;
      left: -65px;
      height: 160px;
      .beta-diagram-img{
        @media screen and (min-width: 540px) {
          top: -20px;
          left: -40px;
        }
        @media screen and (max-width: 539px) {
          top: -20px!important;
          left: 0px;
        }
        height: 100%;
        position: relative;
      }
    }
    .fly-send-img-content{
      position: absolute;
      bottom: -17px;
      right: -54px;
      width: 81px;
      height: 27px;
      
      .fly-send-img{
        height: 100%;
        position: relative;
      }
    }
  }

  .price-plan {
    p.title{
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      color: $color-dark;
      font-family: 'Roboto';
      font-size: 20px;
      font-weight: 900;
      letter-spacing: 0;
      line-height: 19px;
      margin-top: 105px;
    }
    .plan-type{
      height: 32px;
      width: 229px;
      border-radius: 4px;
      margin-top: 17px;
      .btn-plan{
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        color: $color-dark;
        border: solid 1px $color-red;
        font-family: 'Roboto';
        font-size: 12px;
        letter-spacing: 0;
        line-height: 11px;
        padding: 11px 30px 10px 30px;
        &.active{
          font-family: 'Roboto';
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $color-white;
          background: $color-red;
        }
        &:focus{
          outline: none;
        }
        &.monthly {
          border-radius: 4px 0px 0px 4px;
        }
        &.yearly {
          border-radius: 0px 4px 4px 0px;
        }
      }
    }
    .plan-cards-content{
      padding: 30px 0px 30px 0px;
      .slick-slider .slick-track, .slick-slider .slick-list{
        height: 350px;
      }
      .slick-dots{
        bottom: -35px;
        margin-left: 10px;
        padding: 0px;
        text-align: center;
      }
      .slick-dots li.slick-active button:before{
        background: #AA1917;
      }
      .slick-dots li button:before{
        border: 1px solid #AA1917;
      }
      .normal-item{
        margin: 25px 0px 30px 60px;
        padding: 15px 0px 10px 0px;
        width: 191px;
        height: 405px;
        border-radius: 4px;
        background-color: #FFFFFF;
        box-shadow: 0 7px 24px 0 rgba(83,83,83,0.1);
        text-align: center;
        position: relative;
        &:first-child{
          margin-left: 0px;
        }
        .title{
          margin-top: 0px;
          font-weight: 900;
          text-align: center;
          color: #222222;
          font-family: 'Roboto';
          font-size: 14px;
          font-weight: 900;
          letter-spacing: 0;
          line-height: 16px;
        }
        .price{
          color: #AA1917;
          font-family: 'Roboto';
          font-size: 44px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 64px;
          text-align: center;
          margin-top: 0px;
        }
        .plan-unit{
          color: #222222;
          font-family: 'Roboto';
          font-size: 12px;
          letter-spacing: 0;
          line-height: 10px;
          margin-top: 0px;
          margin-right: 15px;
        }
        .plan-items{
          margin-top: 30px;
          text-align: center;
          .item {
            color: #222222;
            font-family: 'Roboto';
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 12px;
            margin-top: 20px;
          }
        }
        .btn-price{
          border-radius: 4px 0 4px 0;
          background-color: $color-red;
          box-shadow: 0 8px 13px 0 rgba(91,91,91,0.6);
          height: 30px;
          width: 140px;
          color: #FFFFFF;
          font-family: 'Roboto';
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 12px;
          margin-top: 20px;
          padding: 9px;
        }
      }

      .slick-initialized .slick-slide{
        & > div {
          display: flex;
          justify-content: center;
        }
      }

      //.slick-slider div.slick-active .normal-item, .slick-slider div.slick-active .main-item {
      //  -webkit-animation: myMove 8s 1 ease-in-out;
      //  -moz-animation: myMove 8s 1 ease-in-out;
      //  -o-animation: myMove 8s 1 ease-in-out;
      //  -ms-animation: myMove 8s 1 ease-in-out;
      //  animation: myMove 8s 1 ease-in-out;
      //}
      //
      //@keyframes myMove {
      //  from { transform: scale(1.0,1.0); transform-origin: 50% 50%; }
      //  to { transform: scale(1.1,1.1); transform-origin: 50% 0%; }
      //}
      //@-webkit-keyframes myMove {
      //  from { -webkit-transform: scale(1.0,1.0); -webkit-transform-origin: 50% 50%; }
      //  to { -webkit-transform: scale(1.1,1.1); -webkit-transform-origin: 50% 0%;  }
      //}
      //@-o-keyframes myMove {
      //  from { -o-transform: scale(1.0,1.0); -o-transform-origin: 50% 50%; }
      //  to { -o-transform: scale(1.1,1.1); -o-transform-origin: 50% 0%;  }
      //}
      //@-moz-keyframes myMove {
      //  from { -moz-transform: scale(1.0,1.0); -moz-transform-origin: 50% 50%; }
      //  to { -moz-transform: scale(1.1,1.1); -moz-transform-origin: 50% 0%; }
      //}
      //@-ms-keyframes myMove {
      //  from { -ms-transform: scale(1.0,1.0); -ms-transform-origin: 50% 50%; }
      //  to { -ms-transform: scale(1.1,1.1); -ms-transform-origin: 50% 0%; }
      //}

      .main-item{
        margin: 0px 0px 30px 0px;
        padding: 30px 0px 30px 0px;
        border-top: 3px solid #AA1917;
        width: 191px;
        height: 430px;
        border-radius: 4px;
        background-color: $color-white;
        box-shadow: 0 7px 24px 0 rgba(83,83,83,0.1);
        text-align: center;
        .title{
          margin-top: 0px;
          font-weight: 900;
          text-align: center;
          color: #222222;
          font-family: 'Roboto';
          font-size: 14px;
          font-weight: 900;
          letter-spacing: 0;
          line-height: 16px;
        }
        .price{
          color: #AA1917;
          text-align: center;
          font-family: 'Roboto';
          font-size: 44px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 64px;
          text-align: center;
          margin-top: 0px;
        }
        .plan-unit{
          line-height: 18px;
          color: #222222;
          font-family: 'Roboto';
          font-size: 12px;
          letter-spacing: 0;
          line-height: 10px;
          margin-top: 0px;
          margin-right: 15px;
        }
        .plan-items{
          margin-top: 30px;
          text-align: center;
          padding: 0px 15px;
          .item {
            color: #222222;
            font-family: 'Roboto';
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 21px;
            margin-top: 20px;
            span {
              margin: 1px 0px 0px 3px;
            }
          }
        }
        .btn-price{
          border-radius: 4px 0 4px 0;
          background-color: $color-red;
          box-shadow: 0 8px 13px 0 rgba(91,91,91,0.6);
          height: 30px;
          width: 140px;
          color: #FFFFFF;
          font-family: 'Roboto';
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 0;
          margin-top: 20px;
          padding: 9px;
          line-height: 12px;
        }
      }
    }
  }

  .functionalies-content{
    margin-top: 45px;
    .title {
      margin-bottom: 28px;
      background-color: transparent;
      height: 25px;
      p{
        color: $color-dark;
        font-weight: 900;
        font-family: 'Roboto';
        font-size: 20px;
        letter-spacing: 0;
        line-height: 20px;
        padding: 0px 43px 0px 43px;
        position: relative;
        &::after{
          content: ' ';
          display: block;
          background: $color-red;
          position: absolute;
          left: 43px;
          bottom: 0px;
          width: 89px;
          height: 1px;
        }
      }
    }
    .collapise-content{
      padding: 0px 18px 0px 18px;
      .first-column {
        .description-content{
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
          color: #787676;
          position: relative;
          padding: 0px 25px 0px 25px;
          font-family: 'Roboto';
          font-size: 14px;
          letter-spacing: 0;
          line-height: 18px;
          text-align: center;
          span{
            font-size: 16px;
            font-weight: bold;
            color: #AA1917;
          }
          .setting-img-content{
            position: absolute;
            top: -50px;
            left: 50px;
            width: 225px;
            height: 225px;
            .setting-img{
              height: 100%;
              position: relative;
            }
          }
        }
      }
    }
  }

  ///////////////////////////////////Collapse STYLES/////////////////////////////////////

  .Collapsible{
    width: 100%;
    border-radius: 4px;
    background-color: $color-white;
    box-shadow: 0 5px 25px 0 rgba(0,0,0,0.04);
    margin-top: 6px;
    position: relative;
    &:hover{
      cursor: pointer;
    }
  }
  .Collapsible__trigger {
    color: $color-dark;
    font-weight: bold;
    letter-spacing: 0;
    padding: 16px 0px 11px 19px;
    display: block;
    text-decoration: none;
    position: relative;
    font-family: 'Roboto';
    font-size: 10px;
    line-height: 11px;
    height: 44px;
  }
  .Collapsible__trigger::after{
    transform: rotateZ(0deg);
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 22px;
    content: '';
    position: absolute;
    right: 16px;
    top: 17px;
    display: block;
    color: #24272C;
    background-image: url(/images/arrow-right-black.png);
    background-size: 12px 12px;
    width: 12px;
    height: 12px;
  }
  .Collapsible__trigger.is-open::after{
    transform: rotateZ(90deg);
    font-family: 'Roboto';
    font-weight: 900;
    font-size: 25px;
    content: '';
    position: absolute;
    right: 20px;
    top: 17px;
    display: block;
    color: #24272C;
    background-image: url(/images/arrow-right-black.png);
    background-size: 12px 12px;
    width: 12px;
    height: 12px;
  }
  .Collapsible__contentOuter {
    border-radius: 4px;
    border: solid 1px #eeeeee;
    background-color: #f5f5f5;
    .Collapsible__contentInner{
      padding: 15px 25px 15px 25px;
      p{
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        color: $color-dark;
        font-family: 'Roboto';
        font-size: 14px;
        letter-spacing: 0;
        line-height: 17px;
        span{
          font-weight: 200!important;
        }
      }
    }
  }

  ///////////////////////////////////Collapse STYLES/////////////////////////////////////

  .progress-img-content{
    width: 733px;
    height: 534px;
    padding-top: 71px;
    .progress-img{
      height: 100%;
      position: relative;
    }
  }

  .customer-say-content {
    padding: 100px 0px 0px 45px;
    position: relative;
    .comments-img-content{
      height: 127px;
      width: 116px;
      .comments-img{
        height: 100%;
        position: relative;
        p.title{
          position:absolute;
          top: 0px;
          left: 0px;
          z-index: 10;
          font-weight: 900;
          letter-spacing: 0;
          color: $color-dark;
          font-family: 'Roboto';
          font-size: 18px;
          line-height: 19px;
          padding: 31px 0px 0px 0px;
          width: 250px;
        }
        &:after{
          content: ' ';
          display: block;
          background: $color-red;
          position: absolute;
          left: 0px;
          top: 55px;
          width: 89px;
          height: 1px;
        }
      }
    }
    .carousal-bg-content{
      margin-top: 73px;
      margin-left: 105px;
      position: absolute;
      right: 0;
      height: 802px;
      opacity: 0.14;
      background-color: #000000;
    }
    .carousal-content {
      margin-top: 73px;
      margin-left: 105px;
      position: absolute;
      right: 0;
      bottom: -50px;
      height: 220px;
      width: 100%;
      padding: 0px 0px 0px 45px;
      .slider-content{
        margin-top:150px;
        position: relative;
        .slider-main-content{
          position: absolute;
          top: 0px;
          .item-red{
            height: 235px;
            background: $color-red;
            border-radius: 6px;
            width: 220px !important;
            padding: 21px 31px 0px 28px;
            .dot-img-content{
              height: 21px;
              width: 9px;
              margin-left: 9px;
              .dot-img{
                position: relative;
                height: 100%;
                width: 100%;
              }
              &:first-child {
                margin-left: 0px;
              }
            }
            .description-content{
              margin-top: 15px;
              p{
                color: #FDF9F9;
                font-family: 'Roboto';
                font-size: 14px;
                font-style: italic;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 20px;
              }
            }
          }
          .item-white{
            background: $color-white;
            height: 235px;
            border-radius: 6px;
            width: 220px !important;
            padding: 21px 31px 0px 28px;
            .dot-img-content{
              height: 21px;
              width: 9px;
              margin-left: 9px;
              .dot-img{
                position: relative;
                height: 100%;
                width: 100%;
              }
              &:first-child {
                margin-left: 0px;
              }
            }
            .description-content{
              margin-top: 15px;
              p{
                color: #787676;
                font-family: 'Roboto';
                font-size: 14px;
                font-style: italic;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }

  /////////////////////// Carousel Part ////////////////////////////
  .slick-dots{
    bottom: -35px;
    padding: 0 0 0 50px;
  }

  .slick-dots li.slick-active button:before{
    background: #ddd;
  }

  .slick-dots li button:before{
    border: 1px solid #ddd;
    width: 7px;
    height: 7px;
  }

  .slick-dots li button{
    width: 7px;
    height: 7px;
  }

  .slick-dots li{
    margin: 0px;
  }

  /////////////////////// Carousel Part ////////////////////////////


  .help-content{
    margin-top: 654px;
    .header{
      height: 125px;
      background-color: $color-white;
      position: relative;
      padding: 0px;
      border: none;
      z-index: 0;
      .title {
        p{
          color: $color-dark;
          font-family: 'Roboto';
          font-size: 36px;
          font-weight: 900;
          letter-spacing: 0;
          line-height: 42px;
          padding: 14px 0px 27px 171px;
        }
        .question-img-content{
          position: absolute;
          top: -104px;
          left: 100px;
          width: 402px;
          height: 680px;
          .question-img{
            height: 100%;
            position: relative;
          }
        }
      }
      .search-input-content{
        align-items: center;
        .search-input-group{
          position: relative;
          width: 561px;
          height: 48px;
          input{
            width: 100%;
            padding: 5px 15px 5px 50px;
            border-radius: 4px;
            background-color: #f5f6f9;
            font-family: 'Roboto';
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #999898;
            height: 100%;
            &:focus{
              outline: none;
            }
          }
          .search-img-content{
            position: absolute;
            left: 18px;
            top: 15px;
            width: 19px;
            height: 19px;
            .search-img{
              position: relative;
              height: 100%;
            }
          }
        }
      }
    }

    .help-section-2{
      margin-top:34px;
      padding: 0px 113px 0px 173px;
      .second-section{
        position: relative;
        p{
          font-family: 'Roboto';
          font-size: 26px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $color-red;
        }
      }
    }

    .help-section-3{
      margin-top:70px;
      padding: 0px 113px 0px 173px;
      .help-first-section{
        padding-right: 65px;
      }
      .help-second-section{

      }
    }
  }

  ///////////////////////////////Article Card//////////////////////////////

  .article-item{
    margin-top: 97px;
    width: 377px;
    height: 306px;
    padding: 164px 0 0;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 0 rgba(152, 149, 149, 0.5);
    border-style: solid;
    //border-width: 0.5px;
    z-index: 90;
    border-image-source: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.02) 95%, rgba(0, 0, 0, 0.04));
    border-image-slice: 1;
    background-image: linear-gradient(to bottom, $color-white, $color-white), linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.02) 95%, rgba(0, 0, 0, 0.04));
    background-origin: border-box;
    background-clip: content-box, border-box;
    position: relative;
    .card-img-content{
      position: absolute;
      top:0px;
      left: 0px;
      width: 100%;
      height: 100%;
      .card-img{
        height: 100%;
        position: relative;
        opacity: 0.38;
      }
    }
    .card-content{
      position: absolute;
      bottom: 0px;
      height: 50%;
      background: rgba(0, 0, 0, 0.1);
      .card-content-wrap{
        position: relative;
        .card-content-background{
          background: #000;
          opacity: 0.1;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0px;
          left: 0px;
        }
        .article-description{
          height: 84px;
          padding: 26px 13px 0px 18px;
          font-family: 'Roboto';
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.69;
          letter-spacing: normal;
          color: $color-dark;
          overflow: hidden;
        }
        .btn-article-see-more{
          font-family: 'Roboto';
          font-size: 15px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          margin: 22px 0px 16px 19px;
          color: $color-red;
          z-index: 1;
        }
      }
    }
    &:first-child{
      margin-top: 20px;
    }
  }

  /////////////////////////////////////////////////////////////////////////

  .help-blog-content {
    margin-top: 60px;
    .header{
      position: relative;
      padding: 0px;
      border: none;
      z-index: 0;
      height: 25px;
      background: transparent;
      .title {
        p{
          color: $color-dark;
          font-weight: 900;
          font-family: 'Roboto';
          font-size: 20px;
          letter-spacing: 0;
          line-height: 19px;
          padding: 0px 43px 0px 43px;
          position: relative;
          &::after{
            content: ' ';
            display: block;
            background: #aa1917;
            position: absolute;
            left: 43px;
            bottom: -5px;
            width: 89px;
            height: 1px;
          }
        }
        .question-img-content{
          position: absolute;
          height: 280px;
          width: 165px;
          top: -35px;
          left: 43px;
          .question-img{
            height: 100%;
            position: relative;
          }
        }
      }
    }

    .search-input-content{
      align-items: center;
      .search-input-group{
        position: relative;
        height: 26px;
        width: 75px;
        input{
          padding: 5px 5px 5px 25px;
          border-radius: 4px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          color: #999898;
          font-family: 'Roboto';
          font-size: 7px;
          letter-spacing: 0;
          line-height: 8px;
          height: 27px;
          width: 76px;
          border: 1px solid #EEEEEE;
          background-color: #FAFAFA;

          &:focus{
            outline: none;
          }
        }
        .search-img-content{
          position: absolute;
          height: 9px;
          width: 9px;
          top: 11px;
          left: 10px;
          .search-img{
            position: relative;
            height: 100%;
          }
        }
      }
    }

    .help-section-2{
      margin-top: 15px;
      padding: 0px 43px 0px 43px;
      .second-section{
        position: relative;
        p{
          font-family: 'Roboto';
          font-size: 26px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $color-red;
        }
      }
    }

    .help-section-3{
      margin-top:0px;
      padding: 0px 43px 0px 43px;
      .help-first-section{
        margin-top: 20px;
        .help-blog-img-content{
          height:auto;
          width: 100%;
          opacity: 1;
          .help-blog-img{
            position: relative;
            height: 100%;
          }
        }
        .blog-title{
          width: 562px;
          margin-top: 24px;
          font-family: 'Roboto';
          font-size: 26px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $color-dark;
        }
        .blog-description{
          width: 100%;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          text-align: justify;
          color: $color-dark;
          margin-top: 10px;
          font-family: 'Roboto';
          font-size: 8px;
          letter-spacing: 0;
          line-height: 15px;
          span{
            font-size: 15px;
            font-weight: 400;
            line-height: 21px;
          }
          p{
            padding-left: 0px!important;
          }
        }
      }
      .help-second-section{
        padding: 0px 30px 0px 30px;
      }
    }

    .help-section-4{
      .img_info image{
        box-sizing: border-box;
        display: block;
        max-width: 100%;
      }
      .article-item{
        height: auto;
        width: 100%;
        opacity: 1;
      }

      .article-item .card-content{
        height: 40px;
        width: 234px;
        top: 64px;
      }

      .article-item .card-img-content .card-img{
        position: relative;
        height: 100%;
      }

      .article-item .card-content .card-content-wrap .article-description{
        height: 25px;
        width: 212px;
        color: #222222;
        font-family: 'Roboto';
        font-size: 8px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 27px;
        padding: 0px 0px 0px 15px;
        z-index: 1;
      }
      .article-item .card-content .card-content-wrap .btn-article-see-more{
        width: 100%;
        color: #AA1917;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 25px;
        margin: 0px 0px 8px 14px;
        text-align: left;
      }

      .article-item .card-content .card-content-wrap .card-content-background{
        opacity: 0.8;
        background-color: #FFFFFF;
      }
    }

    .help-section-5 {
      margin-top: 20px;
      padding: 0px 18px 0px 18px;
    }
  }

  .contact-content{
    margin-top: 87px;
    background-color: #f8f9fc;
    .form-content{
      padding: 0px 42px 26px 42px;
      background: transparent;
      position: relative;
      width: 100%;
      .contact-title{
        width: 100%;
        color: #222222;
        font-family: 'Roboto';
        font-size: 20px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 19px;
        position: relative;
        padding-left: 85px;
        &::after{
          content: ' ';
          display: block;
          background: $color-red;
          position: absolute;
          left: 85px;
          bottom: -5px;
          width: 89px;
          height: 1px;
        }
      }
      .contact-sub{
        color: #787676;
        font-family: 'Roboto';
        font-size: 8px;
        font-style: italic;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 9px;
        margin-top: 15px;
        padding-left: 85px;
      }
      .contact-img-content{
        position: absolute;
        top: -15px;
        left: 43px;
        width: 75px;
        height: 71px;
        .contact-img{
          position: relative;
          width: 75px;
          height: 71px;
        }
      }
    }
    .map-content{
      margin-top: 0px;
      height: 73px;
      width: 100%;
      position: relative;
      .map-overlay{
        width: 100%;
        height: 100%;
        position: absolute;
        background: #ffffff;
        opacity: 0.7;
        top: 0px;
      }
      .contact-info-content{
        position: absolute;
        top: 0px;
        height: 73px;
        padding-top: 21px;
        p{
          color: #030303;
          font-family: 'Roboto';
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 10px;
        }
      }
      .place-icon{
        height: 12px;
        width: 8px;
        position: relative;
      }
      .phone-icon{
        height: 8.8px;
        width: 8px;
        position: relative;
      }
      .envelope-icon{
        height: 6px;
        width: 8px;
        position: relative;
      }
    }
  }

  .contact-form-content {
    margin-top: 15px;
    justify-content: center;
    .btn-contact{
      border-radius: 4px;
      background-color: $color-red;
      height: 30px;
      width: 100px;
      color: #FFFFFF;
      font-family: 'Roboto';
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 10px;
      text-align: center;
      margin-top: 30px;
    }
  }

  .input-group{
    margin-top: 45px;
    position: relative;
    input{
      width: 100%;
      background: transparent;
      border-bottom: 2px solid $color-red;
      height: 30px;
      color: #222222;
      font-family: 'Roboto';
      font-size: 11px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      &:focus {
        outline: none;
      }
    }
    .warning-msg{
      position: absolute;
      top: 38px;
      left: 0px;
      color: $color-red;
      font-family: 'Roboto';
      font-size: 12px;
      font-style: italic;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 13px;
    }
    .warning-icon{
      position: absolute;
      top: 35px;
      right: 0px;
      color: $color-red;
      height: 15px;
      width: 17px;
    }
  }

  .footer{
    margin-top: 55px;
    margin-bottom: 100px;
    .copyright{
      font-stretch: normal;
      font-style: normal;
      color: #070707;
      color: #070707;
      font-family: 'Roboto';
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 10px;
      .year{
        color: $color-red;
      }
      .strong{
        color: $color-red;
        font-weight: 900;
      }
    }
    .footer-menu{
      margin-top: 69px;
      margin-bottom: 100px;
      .menu-item{
        font-family: 'Roboto';
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $color-dark;
        padding-left: 30px;
        &::after{
          display: block;
          content: "/";
          font-family: 'Roboto';
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $color-dark;
          margin-left: 30px;
        }
        &:last-child::after{
          display: block;
          content: "";
        }
      }
    }

  }

  .login{
    &.modal{
      left: 0px;
      top: 70px;
      .modal-content{
        padding: 80px 32px 25px 32px;
        width: 100% !important;

        nav.tabs{
          button.tab.active{
            border-bottom: 2px solid #AA1A18;
            color: #AA1A18;
          }

          button.tab{
            padding: 0px 20px 20px 20px;
            border-bottom: 1px solid #DEDEDE;
            color: rgba(150,150,156,0.64);
            font-family: 'Roboto';
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.44px;
            line-height: 16px;
            &:focus {
              outline: none;
            }
          }
        }

        .contact-form-content{
          .login-input-group .login-email-input, .login-input-group .login-pwd-input{
            color: rgba(39,39,39,0.4);
            font-family: 'Roboto';
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.38px;
            line-height: 14px;
            padding: 14px 0px 12px 20px;
            height: 40px;
            border: 1px solid #DEDEDE;
            border-radius: 4px;
            background-color: #F5F6F8;
          }

          .login-input-group .login-pwd-input{
            padding: 14px 40px 12px 20px;
          }

          .login-input-group{
            position: relative;
            .btn-visible{
              position: absolute;
              right: 0px;
              top: 0px;
              height: 40px;
              width: 40px;
              padding-right: 5px;
              opacity: 0.34;
              color: #AA1A18;
              font-family: 'Roboto';
              font-size: 12px;
              font-weight: 500;
              letter-spacing: 0.28px;
              line-height: 10px;
              text-align: right;
              &:focus{
                outline: none;
              }
            }
          }

          .btn-login{
            border-radius: 4px;
            background-color: $color-red;
            height: 30px;
            width: 100%;
            color: #FFFFFF;
            font-family: 'Roboto';
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 10px;
            text-align: center;
            margin-left: 0px;
            margin-top: 30px;
          }

          label .checkbox-label{
            color: #919191;
            font-family: 'Roboto';
            font-size: 12px;
            letter-spacing: 0;
            line-height: 10px;
            text-align: right;
          }
        }
      }
    }
  }

  #menuModal{
    &.modal{
      right: 0px;
      top: 0px;
      box-shadow: 0 5px 12px 0 rgba(152,149,149,0.5);
    }
    .modal-content{
      padding: 20px 20px 25px 20px;
      width: 268px !important;
      height: auto !important;
      .modal-close{
        right: 20px;
        top: 20px
      }
      .menu-content{
        margin-top: 30px;
      }
      .menu-item {
        margin-right: 0px;
        height: 45px;
        a {
          color: #000000;
          font-family: 'Roboto';
          font-size: 12px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 23.7px;
          border-bottom: 1px solid #ddd;
          width: 100%;
          padding: 10px 0px 10px 0px;
          position: relative;
          svg{
            position: absolute;
            right: 0px;
            top: 20px;
          }
        }
      }

      .search-input-content{
        align-items: center;
        .search-input-group{
          position: relative;
          width: 100%;
          height: 33px;
          input{
            width: 100%;
            padding: 5px 15px 5px 33px;
            border-radius: 4px;
            background-color: transparent;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            height: 33px;
            color: #ACA0A0;
            font-family: 'Roboto';
            font-size: 12px;
            letter-spacing: 0;
            line-height: 13px;
            border: 1px solid #222222;
            &:focus{
              outline: none;
            }
          }
          .search-img-content{
            position: absolute;
            top: 10px;
            left: 10px;
            width: 12px;
            height: 12px;
            .search-img{
              position: relative;
              height: 100%;
            }
          }
        }
      }
      .copyright{
        margin-top: 20px;
        font-stretch: normal;
        font-style: normal;
        color: #070707;
        color: #070707;
        font-family: 'Roboto';
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 10px;
        .year{
          color: $color-red;
        }
        .strong{
          color: $color-red;
          font-weight: 900;
        }
      }
    }
  }

  ////////////////////////////Custom DropDown//////////////////////////////////
  .custom-dropdown{
    width: 75px;
    height: 26px;
    right: 43px;
    background: transparent;
    box-shadow: none;
    border: none;
  }

  .custom-dropdown button{
    width: 75px;
    height: 26px;
    color: #AA1917;
    font-family: 'Roboto';
    font-size: 8px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    padding: 5px 0px 5px 15px;
  }

  .custom-dropdown button svg{
    width: 11px;
    height: 10px;
    margin-left: 15px;
  }

  .custom-dropdown ul{
    width: 120px;
  }

  .custom-dropdown ul li > a{
    color: rgba(0, 0, 0, 0.87);
    font-family: 'Roboto';
    font-size: 8px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    padding: 3px 0px 5px 15px;
  }

  ////////////////////////////////////////////////////////////////////////////
}

.mobile .beta-contact-content p.sub-title
{
  margin-left: 0px;
}

.mobile .video_list_image .web-instruction .logo{
  padding: 8px 8px;
}

.mobile .passion-title-content .steps-slider-content .slick-prev:before, .mobile .passion-title-content .steps-slider-content .slick-next:before{
  opacity: 0.2;
}

.mobile .beta-contact-content p.sub-title{
  font-weight: normal;
}

.mobile .functionalies-content .collapise-content .first-column .description-content{
  color: #787676;
  font-family: 'Roboto';
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
  text-align: justify;
  padding-bottom: 20px;
}

.mobile .functionalies-content .title{
  margin-bottom: 18px;
}

.mobile .functionalies-content .collapise-content .first-column .description-content .setting-img-content{
  top: -40px;
}

.mobile .Collapsible__trigger{
  color: #222222;
  font-family: 'Roboto';
  font-size: 15px;
    font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
}

.mobile .Collapsible__contentOuter .Collapsible__contentInner p{
  color: #222222;
  font-family: 'Roboto';
  font-size: 15px;
  letter-spacing: 0;
  line-height: 19px;
}

.mobile .slick-dots{
  padding-left: 0px;
  text-align: center;
}

.mobile .help-blog-content .search-input-content .search-input-group input{
  height: 35px;
  width: 118px;
  color: #999898;
  font-family: 'Roboto';
  font-size: 12px;
  letter-spacing: 0;
  line-height: 10px;
}

.mobile .help-blog-content .search-input-content .search-input-group .search-img-content{
  width: 11px;
  height: 11px;
  top: 13px;
}

.mobile .custom-dropdown button{
  color: #BB0002;
  font-family: 'Roboto';
  font-size: 10px;
  letter-spacing: 0;
  line-height: 20px;
  padding-left: 7px;
  width: 75px;
  height: 35px;

}

.mobile .help-blog-content .help-section-3 .help-first-section .blog-description{
  color: #222222;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
  text-align: justify;
  h1{
    padding-top: 10px;
    padding-bottom: 10px;
    span{
      font-weight: 400!important;
    }
  }
}
.mobile .help-blog-content .help-section-3 .help-first-section .blog-description-text{
  padding-bottom: 20px;
  span{
    color: #787676;
      font-family: 'Roboto';
      font-size: 16px;
      font-style: italic;
      font-weight: 200!important;
      letter-spacing: 0;
      line-height: 24px;
      text-align: justify;
  }
}
.mobile .help-blog-content .help-section-4 .article-item .card-content{
  height: 49px;
  top: 82px;
  width: 100%;
}

.mobile .article-item .card-content .card-content-wrap{
  height: 75px;
}

.mobile .help-blog-content .help-section-4 .article-item .card-content .card-content-wrap .article-description{
  color: #222222;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    padding-top: 8px;
    height: 65%;
    width: 100%;
}

.mobile .contact-content .form-content .contact-sub{
  color: #787676;
  font-family: 'Roboto';
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 14px;
  margin-top: 10px;
}

.mobile .input-group input{
  color: #222222;
  font-family: 'Roboto';
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

.mobile .contact-content .map-content .place-icon{
  width: 12px;
  height: 18px;
}

.mobile .contact-content .map-content .contact-info-content p{
  margin-top: 10px;
}


.mobile .footer .social-icons-content .social-item{
  width: 26px;
  height: 26px;
}



.mobile .footer .social-icons-content{
  padding: 0px 60px 30px 60px;
}

.mobile .contact-form-content .btn-contact{
  font-weight: normal;
}

.mobile .contact-content .map-content .phone-icon{
  height: 16px;
  width: 15px;
  margin-bottom: 2px;
}

.mobile .contact-content .map-content .envelope-icon{
  height: 13px;
    width: 16px;
    margin-bottom: 4px;
}

.mobile .help-blog-content .help-section-4{
  margin-top: 0px;
}

@media screen and (max-width: 375px){
  .passion-title-content .slick-track {
      width: 2200px!important;
  }
}
.plan-cards-content .slick-list{
  height: 460px!important;
}
.blog_hires{
  padding-left: 0px;
}
@media screen and (min-width: 2880px){
  .blog_hires{
    padding-left: 20px;
  }
}


.blog-description span {
  color: #222222;
  font-family: 'Roboto';
  font-size: 15px;
  /* font-style: italic; */
  font-weight: 200!important;
  letter-spacing: 0;
  line-height: 25px;
  text-align: justify;
}


.social-icons-content{
  padding: 0px 340px 30px 340px;

  .social-item{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #aa1917;
    position: relative;
    padding: 5px;

    .icon-facebook{
      color: #aa1917;
      font-size: 16px;
      font-weight: 400;
      width: 11px;
      cursor: pointer;
    }

    .icon-twitter{
      color: #aa1917;
      font-size: 16px;
      font-weight: 400;
      width: 15px;
      cursor: pointer;
    }

    .icon-instagram{
      color: #aa1917;
      font-size: 16px;
      font-weight: 400;
      width: 15px;
      cursor: pointer;
    }
    .icon-linkedin{
      color: #aa1917;
      font-size: 16px;
      font-weight: 400;
      width: 15px;
      cursor: pointer;
    }
  }
}
.politica{
  span{
    width: 10%;
    font-size: 10px;
    padding: 20px 0px;
    color: #787676;
    font-weight: 200;
  }
}
.mobile .politica span{
  display: block;
  text-align: center;
  width: 100%!important;
  padding: 12px 0px 0px 0px;
}