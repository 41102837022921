.slick-loading .slick-list
{
  background: #fff url('/images/ajax-loader.gif') center center no-repeat;
}

/* Icons */
@font-face
{
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;

  src: url('/fonts/slick.eot');
  src: url('/fonts/slick.eot?#iefix') format('embedded-opentype'), url('/fonts/slick.woff') format('woff'), url('/fonts/slick.ttf') format('truetype'), url('/fonts/slick.svg#slick') format('svg');
}
/* Arrows */
.slick-prev,
.slick-next
{
  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 50%;

  display: block;

  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
  color: transparent;
  outline: none;
  background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
  opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;

  opacity: .75;
  color: white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
  left: -25px;
}
[dir='rtl'] .slick-prev
{
  right: -25px;
  left: auto;
}
.slick-prev:before
{
  content: '←';
}
[dir='rtl'] .slick-prev:before
{
  content: '→';
}

.slick-next
{
  right: -25px;
}
[dir='rtl'] .slick-next
{
  right: auto;
  left: -25px;
}
.slick-next:before
{
  content: '→';
}
[dir='rtl'] .slick-next:before
{
  content: '←';
}

/* Dots */
.slick-dotted.slick-slider
{
  margin-bottom: 30px;
}

.slick-dots
{
  position: absolute;
  bottom: -75px;

  display: block;

  width: 100%;
  padding: 0 0 0 250px;
  margin: 0;
  list-style: none;
  text-align: left;
  @media screen and (min-width: 1440.05px) {
    padding: 0 0 0 380px;
  }
  @media screen and (max-width: 1440px) {
    padding: 0 0 0 350px;
  }
  @media screen and (max-width: 1280px) {
    padding: 0 0 0 280px;
  }
  @media screen and (max-width: 1024px) {

  }
}
.slick-dots li
{
  position: relative;

  display: inline-block;

  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;

  cursor: pointer;
}
.slick-dots li button
{
  font-size: 0;
  line-height: 0;

  display: block;

  width: 20px;
  height: 20px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
  opacity: 1;
}
.slick-dots li button:before
{
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 17px;
  height: 17px;
  content: '';
  display: block;
  text-align: center;
  opacity: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: 3px solid #fff;
  border-radius: 17px;
}
.slick-dots li.slick-active button:before
{
  opacity: 1;
  background: #ffffff;
}

.slick-arrow{
  display: none !important;
}

/* Slider */
.slick-slider
{
  width: 100%;
  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list
{
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus
{
  outline: none;
}
.slick-list.dragging
{
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.web-instruction .slick-track
{
  @media screen and (min-width: 768px) {
    width:2456px!important ;
  }
}
.price-plan .plan-cards-content {
  @media screen and (min-width: 768px) {
    padding: 64px 20px 64px 20px!important;
  }
}

.carousal-content .slick-track
{
  @media screen and (min-width: 375px) {
    width:1600px!important ;
  }
  @media screen and (min-width:1024px) {
    width:1650px!important ;
  }
  @media screen and (min-width:1280px) {
    width:2300px!important ;
  }
  
    @media screen and (min-width: 1366px) {
      width:2300px!important ;
    }
  position: relative;
  top: 0;
  left: 0;

  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
  display: table;

  content: '';
}
.slick-track:after
{
  clear: both;
}
.slick-loading .slick-track
{
  visibility: hidden;
}

.slick-slide
{
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide
{
  float: right;
}
.slick-slide img
{
  display: block;
}
.slick-slide.slick-loading img
{
  display: none;
}
.slick-slide.dragging img
{
  pointer-events: none;
}
.slick-initialized .slick-slide
{
  display: block;
}
.slick-loading .slick-slide
{
  visibility: hidden;
}
.slick-vertical .slick-slide
{
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.plan-items svg{
  margin-top:5px
}
.plan-items span{
  line-height: 20px;
  font-size: 16px;
}

@media screen and (max-width: 375px){
.plan-cards-content .main-item{
  height: 430px!important;
}
.plan-cards-content .normal-item{
  height: 430px!important;
  margin: 0px 0px 30px 0px!important;
    padding: 30px 0px 30px 0px!important;
}
}